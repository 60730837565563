import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import { Link } from 'gatsby';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';

const Result = () => {
  const theme = useTheme();
	const [filterData, setFilterData] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [versions, setVersions] = React.useState(null);
	const [values, setValues] = useState({
	    filter: '',
	});
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClick = (event,item) => {
    setAnchorEl(event.currentTarget);
		setVersions(item);
		
  };
  const handleClose = () => {
    setAnchorEl(null);
		setVersions(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
	
	const searching = () => {
		
		fetch("https://flomesh.io:1337/docs?_sort=sort:ASC", {
			cache: 'no-cache', 
			headers: {
			  'content-type': 'application/json'
			},
			method: 'GET', 
			mode: 'cors', 
			redirect: 'follow', 
			referrer: 'no-referrer', 
		}).then(response => response.json()
		).then(data => {
			console.log(data)
			setFilterData(data);
		}).catch(error => {
			console.log("$$error:")
			console.log(error)
		});
	}
	if(!filterData){
		searching();
	}
  return (
    <Box sx={{minHeight:400}}>
      {filterData?
	  <Grid container spacing={4}>
        {filterData.map((item, i) => (
					<Grid item xs={12} sm={6} md={4} key={i} >
						<div style={{position:"relative"}}>
						<Box
							aria-describedby={id} variant="contained" onClick={(e) => handleClick(e,item.content)}
							display={'block'}
							width={1}
							height={1}
							sx={{
								textDecoration: 'none',
								transition: 'all .2s ease-in-out',
								'&:hover': {
									transform: `translateY(-${theme.spacing(1 / 2)})`,
								},
							}}
						>
							<Box
								component={Card}
								width={1}
								height={1}
								boxShadow={4}
								display={'flex'}
								flexDirection={'row'}
								sx={{ background: '#fafafa' }}
							>
								<Box sx={{ background: '#fff',borderRadius:"0 0 50% 0" }}>
									<CardMedia
										image={'https://flomesh.io:1337'+item.icon[0].url}
										title={item.type}
										sx={{
											height: { xs: 80, md: 80 },
											width: { xs: 80, md: 80 },
											margin: '7px 10px',
											position: 'relative',
										}}
									>
									</CardMedia>
								</Box>
								<Box component={CardContent} position={'relative'}>
									<Typography variant={'h6'} gutterBottom>
										{item.type}
									</Typography>
									<Typography color="text.secondary">
										{item.content[0].version} <Chip sx={{marginLeft:'10px'}} label="Latest" size="small" />
									</Typography>
								</Box>
								<Box flexGrow={1} />
								<Box padding={2} display={'flex'} flexDirection={'column'}>
									<ArrowDropDownCircleIcon sx={{ flex:1,cursor: 'pointer',color: '#00adef',fontSize:'2em',position:'relative',top:0}}/>
								</Box>
							</Box>
						</Box>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							sx={{
								'.MuiPaper-root': {
									width: '300px',
									marginTop: '0px',
									borderTopRightRadius: 0,
									borderTopLeftRadius: 0,
									borderBottomRightRadius: 8,
									borderBottomLeftRadius: 8,
									borderTop: `3px solid ${theme.palette.primary.main}`,
								},
							}}
						>
						{versions?
								<Grid container spacing={0.5}>
									{versions.map((p, i) => (
										<Grid item key={i} xs={versions.length > 12 ? 6 : 12}>
											<Button
												component={'a'}
												target={'target'}
												href={p.url}
												fullWidth
												sx={{
													justifyContent: 'flex-start',
													color:theme.palette.text.primary,
													backgroundColor:'transparent',
													fontWeight: 400,
												}}
											>
												{p.version} {i==0?<Chip label="Latest" size="small" sx={{marginLeft:'15px'}}/>:null}
												<ChevronRightIcon sx={{ position:'absolute',right:'10px',flex:1,cursor: 'pointer',color: '#aaa',fontSize:'1.5em'}}/>
											</Button>
										</Grid>
								))}
							</Grid>
							:null
							}
						</Popover>
						</div>
					</Grid>
        ))}
      </Grid>
	:null}
    </Box>
  );
};

export default Result;
